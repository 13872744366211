@import '../utilities/variables';

.#{$kb-prefix} {
    &-project-modal {
        position: fixed;
        inset: 0;
        z-index: 9000;
        opacity: 0;
        transition: opacity 250ms ease;

        &--shown {
            transition-duration: 50ms;
            opacity: 1;
        }

        &__backdrop {
            position: absolute;
            pointer-events: none;
            overflow: hidden;
            opacity: 0;
            transition: all 250ms ease, opacity 50ms ease;

            &::after {
                content: '';
                display: block;
                position: absolute;
                inset: 0;
                background-color: var(--kb-color-project-background);
            }
        }

        &__background {
            display: block;
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1.25);
            filter: blur(15px) saturate(0.75);
        }

        &__content {
            height: 100%;
            overflow-y: auto;
            padding: 4rem;
            z-index: 1;
            opacity: 0;
            transition: opacity 500ms ease 350ms;

            @media #{$kb-mq-medium} {
                padding: 3rem;
            }

            @media #{$kb-mq-x-small} {
                padding: 2rem;
            }

            &--shown {
                opacity: 1;
            }
        }

        &__close {
            display: block;
            cursor: pointer;
            background-color: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            color: var(--kb-tex-color);
            font-size: 2.5rem;
            line-height: 0;
            width: 3rem;
            height: 3rem;
            padding: 0;
            border: none;
            border-radius: 0.25rem;
            position: absolute;
            top: 0.2rem;
            right: -0.75rem;

            @media #{$kb-mq-medium} {
                font-size: 2.125rem;
                width: 2.25rem;
                height: 2.25rem;
                right: -0.5rem;
            }

            @media #{$kb-mq-x-small} {
                font-size: 1.75rem;
                width: 2rem;
                height: 2rem;
            }

            &:hover,
            &:focus,
            &:active {
                color: var(--kb-color-brand);
            }

            &:focus,
            &:active {
                outline: none;
                box-shadow: var(--kb-shadow--focus);
            }
        }

        &__header {
            display: block;
            padding-right: 4rem;
            line-height: 1.25;
            margin: -0.625rem 0 0 0;

            @media #{$kb-mq-medium} {
                padding-right: 3rem;
            }

            @media #{$kb-mq-small} {
                padding-right: 2.5rem;
            }

            @media #{$kb-mq-x-small} {
                margin-top: -0.375rem;
            }
        }

        &__body {
            @media #{$kb-mq-small} {
                display: flex;
                flex-direction: column-reverse;
            }
        }

        &__detail {
            flex: 0 1 auto;
            padding-right: 2rem;
            margin-top: 1.5rem;

            @media #{$kb-mq-small} {
                padding-right: 1.75rem;
                margin-top: 1.25rem;
            }

            &-list {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                list-style: none;
                padding: 0;
                margin: 0;
            }
        }

        &__carousel {
            margin-top: 2rem;

            @media #{$kb-mq-small} {
                margin-bottom: 0.5rem;
            }
        }
    }
}
