@import '../utilities/variables';

@keyframes wave {
    0% {
        transform: none;
    }

    25% {
        transform: rotate(-15deg);
    }

    50% {
        transform: none;
    }

    75% {
        transform: rotate(-15deg);
    }

    100% {
        transform: none;
    }
}

.#{$kb-prefix} {
    &-hero {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 8rem;
        opacity: 0;

        @media #{$kb-mq-large} {
            justify-content: space-evenly;
            padding: 6rem 0;
        }

        @media #{$kb-mq-medium} {
            flex-wrap: wrap;
            justify-content: flex-end;
            padding: 6rem;
            opacity: 1;
        }

        @media #{$kb-mq-small} {
            padding: 3rem;
        }

        @media #{$kb-mq-x-small} {
            padding: 2rem;
        }

        &--shown {
            opacity: 1;
            transition: opacity 500ms ease 750ms;
        }

        &__logo {
            display: block;
            width: 10rem;
        }

        &__content {
            flex: 0 0 auto;

            @media #{$kb-mq-medium} {
                flex: 0 0 100%;
            }

            br {
                @media #{$kb-mq-small} {
                    display: none;
                }
            }

            h1,
            p {
                @media #{$kb-mq-small} {
                    padding-right: 4rem;
                }

                @media #{$kb-mq-x-small} {
                    padding-right: 0;
                }
            }
        }

        &__wave {
            display: inline-block;
            transform-origin: right bottom;

            .#{$kb-prefix}-hero--shown & {
                animation: wave 500ms linear 1s;

                @media #{$kb-mq-medium} {
                    animation-delay: 0ms;
                }
            }

            &-container {
                display: inline-block;
                cursor: grab;

                &:hover .#{$kb-prefix}-hero__wave {
                    animation-iteration-count: infinite;
                }
            }
        }

        &__description {
            margin-top: 2rem;
            max-width: 30rem;

            @media #{$kb-mq-medium} {
                max-width: 36rem;
            }

            @media #{$kb-mq-small} {
                max-width: none;
            }
        }

        &__sidebar {
            flex: 0 0 auto;
            padding-top: 8.375rem;

            @media #{$kb-mq-medium} {
                padding-top: 0;
                margin-bottom: 2rem;
            }

            @media #{$kb-mq-x-small} {
                margin-bottom: 1.5rem;
            }
        }

        &__link {
            display: block;
            position: relative;
            color: var(--kb-color-text);
            text-decoration: none;

            &-list {
                list-style: none;
                padding-left: 2.75rem;
                display: flex;
                margin: 1.25rem 0 0;
            }

            a {
                display: block;
                padding: 0.5rem 0.525rem;
                border-radius: 0.25rem;
                color: var(--kb-color-text);
                text-decoration: none;
                line-height: 1;

                &:hover,
                &:focus,
                &:active {
                    color: var(--kb-color-brand);
                }

                &:focus,
                &:active {
                    outline: none;
                    box-shadow: var(--kb-shadow--focus);
                }
            }

            &-label {
                display: block;
                font-size: 0.8rem;
                color: var(--kb-color-brand);
                pointer-events: none;
                position: absolute;
                top: calc(100% - 0.125rem);
                left: 50%;
                transform: translateX(-50%) translateY(-0.5rem);
                opacity: 0;
                transition: all 250ms ease;

                .#{$kb-prefix}-hero__link > a:hover + & {
                    opacity: 1;
                    transform: translateX(-50%);
                }
            }
        }

        &__controls {
            padding: 0 0 0 3rem;
            margin-top: 2.625rem;
            border: none;

            @media #{$kb-mq-medium} {
                padding: 0;
                margin-top: 4rem;
                width: 100%;
                flex: 1 1 100%;
            }

            @media #{$kb-mq-small} {
                margin-top: 1.25rem;
            }
        }

        &__filters {
            @media #{$kb-mq-medium} {
                display: flex;
            }

            @media #{$kb-mq-x-small} {
                display: block;
                margin-bottom: 0.5rem;
            }

            .#{$kb-prefix}-checkbox {
                @media #{$kb-mq-medium} {
                    margin-right: 0.5rem;
                }

                @media #{$kb-mq-x-small} {
                    margin-right: 0;
                }
            }
        }
    }
}
