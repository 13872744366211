@import '../utilities/variables';

.#{$kb-prefix} {
    &-project-gallery {
        transition: transform 500ms ease 500ms;

        &--shifted {
            transform: translateY(calc(var(--kb-hero-height) * -1));

            @media #{$kb-mq-medium} {
                transform: none;
            }
        }

        &__list {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            pointer-events: none;

            &::before,
            &::after {
                content: '';
                display: block;
                flex: auto;
            }

            @media #{$kb-mq-medium} {
                pointer-events: auto;
            }

            &--enabled {
                pointer-events: auto;
            }
        }

        &__project {
            flex: 0 0 20%;
            overflow: hidden;

            @media #{$kb-mq-x-large} {
                flex-basis: 25%;
            }

            @media #{$kb-mq-medium} {
                flex-basis: 33.333%;
            }

            @media #{$kb-mq-small} {
                flex-basis: 50%;
            }
        }

        &__link {
            display: block;
            position: relative;
            aspect-ratio: 6 /4;
        }

        &__thumbnail {
            position: absolute;
            inset: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            margin-top: 100%;
            opacity: 0;
            transition: all 250ms ease;

            @media #{$kb-mq-medium} {
                margin-top: 0;
                opacity: 1;
            }

            .#{$kb-prefix}-project-gallery__project--loaded &,
            .#{$kb-prefix}-project-gallery__project--shown & {
                margin-top: 0;
                opacity: 1;
            }

            .#{$kb-prefix}-project-gallery__project--shown & {
                transition-property: transform, filter;
            }

            .#{$kb-prefix}-project-gallery__link:hover &,
            .#{$kb-prefix}-project-gallery__link:focus & {
                transform: scale(1.05);
                filter: blur(5px) saturate(0.75);
            }
        }

        &__hover-tile {
            position: absolute;
            inset: 0;
            padding: 15% 15%;
            opacity: 0;
            transition: opacity 250ms ease;
            background-color: var(--kb-color-project-background);

            @media #{$kb-mq-large} {
                padding: 13.25%;
            }

            @media #{$kb-mq-medium} {
                padding: 10%;
            }

            .#{$kb-prefix}-project-gallery__link:hover &,
            .#{$kb-prefix}-project-gallery__link:focus & {
                opacity: 1;
            }
        }

        &__name,
        &__tags {
            display: block;
            position: relative;
            color: var(--kb-color-text);
            transform: translateX(-1rem);
            transition: all 250ms ease 150ms;
            opacity: 0;

            .#{$kb-prefix}-project-gallery__link:hover &,
            .#{$kb-prefix}-project-gallery__link:focus & {
                transform: none;
                opacity: 1;
            }
        }

        &__name {
            font-weight: 700;
            font-size: 1.15rem;
            line-height: 1.25;
            color: var(--kb-color-brand);
            transition-delay: 100ms;
            padding-right: 25%;

            @media #{$kb-mq-large} {
                font-size: 1.125rem;
                padding-right: 0;
            }

            @media #{$kb-mq-small} {
                display: none;
            }
        }

        &__tags {
            list-style: none;
            padding: 0;
            margin: 0.75rem 0 0;
            display: flex;

            @media #{$kb-mq-small} {
                display: none;
            }

            > li {
                font-size: 0.75rem;
                padding: 0.25rem;
                margin-right: 0.25rem;
                line-height: 1;
                border: thin solid var(--kb-color-tag-border);
                border-radius: 0.25rem;
            }
        }
    }
}
