@import '../utilities/variables';

.#{$kb-prefix} {
    &-carousel {
        width: 100%;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        &__container {
            position: relative;
        }

        &__rail {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: nowrap;
        }

        &__video-container {
            background-color: black;
            aspect-ratio: 16 / 9;
            position: relative;
            overflow: hidden;
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
            height: auto;
            scroll-snap-align: start;

            iframe {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
            }
        }

        &__image {
            display: block;
            aspect-ratio: 12 / 8;
            flex: 0 0 100%;
            scroll-snap-align: start;
        }

        &__back,
        &__next {
            display: block;
            cursor: pointer;
            background-color: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            color: var(--kb-tex-color);
            font-size: 2.25rem;
            line-height: 0;
            width: 3rem;
            height: 3rem;
            padding: 0;
            border: none;
            border-radius: 0.25rem;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: -3.5rem;
            transform: translateY(-50%);

            @media #{$kb-mq-medium} {
                font-size: 2rem;
                width: 2.25rem;
                height: 2.25rem;
                left: -2.6rem;
            }

            @media #{$kb-mq-x-small} {
                font-size: 1.25rem;
                width: 1.5rem;
                height: 1.5rem;
                left: -1.75rem;
            }

            &::before {
                display: block;
                transform: translateX(0.125rem);

                @media #{$kb-mq-x-small} {
                    transform: translateX(0.05rem);
                }
            }

            &:hover,
            &:active {
                color: var(--kb-color-brand);
            }

            &:focus,
            &:active {
                outline: none;
                box-shadow: var(--kb-shadow--focus);
            }
        }

        &__next {
            left: initial;
            right: -3.5rem;

            @media #{$kb-mq-medium} {
                right: -2.6rem;
            }

            @media #{$kb-mq-x-small} {
                right: -1.75rem;
            }

            &::before {
                transform: translateX(-0.125rem);

                @media #{$kb-mq-x-small} {
                    transform: translateX(-0.05rem);
                }
            }
        }

        &__nav {
            display: flex;
            padding: 0.5rem;
            border-radius: 0.75rem;
            position: absolute;
            top: 1.125rem;
            left: 1.125rem;
            background-color: var(--kb-color-carousel-nav-background);

            @media #{$kb-mq-small} {
                top: 1rem;
                left: 1rem;
                transform-origin: left top;
                transform: scale(0.8);
            }

            @media #{$kb-mq-x-small} {
                top: 0.5rem;
                left: 0.5rem;
                transform: scale(0.6);
            }

            &-item {
                display: block;
                cursor: pointer;
                margin: 0 0.125rem;
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 0.5rem;
                background-color: var(--kb-color-text);

                &:hover {
                    transform: scale(1.25);
                }

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                &--active {
                    background-color: var(--kb-color-brand);
                }
            }
        }
    }
}
