$kb-prefix: 'kb';
$kb-mq-x-large: 'only screen and (max-width: 1440px)';
$kb-mq-large: 'only screen and (max-width: 1280px)';
$kb-mq-medium: 'only screen and (max-width: 1024px)';
$kb-mq-small: 'only screen and (max-width: 768px)';
$kb-mq-x-small: 'only screen and (max-width: 480px)';

@mixin custom-properties {
    --kb-vh: 1vh;
    --kb-font-family-display: 'Libre Bodoni', serif;
    --kb-font-family-body: 'Inter', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    --kb-color-brand: #f68b1f;
    --kb-color-text: white;
    --kb-color-text-link: var(--kb-color-brand);
    --kb-color-text-link-hover: #d8791a;
    --kb-color-background: #1e1e1e;
    --kb-color-project-background: rgba(0, 0, 0, 0.8);
    --kb-color-carousel-nav-background: rgba(0, 0, 0, 0.2);
    --kb-color-checkbox-border: rgba(136, 136, 136, 0.5);
    --kb-color-tag-border: rgba(136, 136, 136, 0.5);
    --kb-spacing-xx-small: 0.25rem;
    --kb-spacing-x-small: 0.5rem;
    --kb-spacing-small: 0.75rem;
    --kb-spacing-medium: 1rem;
    --kb-spacing-large: 1.5rem;
    --kb-spacing-x-large: 2rem;
    --kb-spacing-xx-large: 3rem;
    --kb-shadow--focus: 0 0 4px var(--kb-color-brand);
}