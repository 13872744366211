@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Libre+Bodoni:ital@0;1&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css');
@import '../utilities/variables';
@import '../utilities/utilities';

* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

body {
    @include custom-properties;
    min-height: calc(var(--kb-vh) * 100);
    margin: 0;
    background-color: var(--kb-color-background);
    color: var(--kb-color-text);
    font-family: var(--kb-font-family-body);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
}

a {
    color: var(--kb-color-text-link);

    &:hover {
        color: var(--kb-color-text-link-hover);
    }
}

.#{$kb-prefix} {
    &-freeze {
        overflow: hidden;
    }

    &-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4rem;
        opacity: 0;
        transition: opacity 500ms ease 750ms;

        @media #{$kb-mq-large} {
            padding: 6rem;
        }

        @media #{$kb-mq-medium} {
            opacity: 1;
        }

        @media #{$kb-mq-small} {
            padding: 3rem;
        }

        @media #{$kb-mq-x-small} {
            padding: 2rem;
        }

        &--shown {
            opacity: 1;
        }

        p {
            margin: 0;

            + p {
                @media #{$kb-mq-small} {
                    margin-top: 0.5rem;
                }
            }
        }
    }

    @include utilities;
}
