@import '../utilities/variables';

.#{$kb-prefix} {
    &-checkbox {
        line-height: 1;

        &__input {
            display: block;
            width: 1px;
            height: 1px;
            border: 0;
            clip: rect(0 0 0 0);
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            pointer-events: auto;
        }

        &__faux {
            display: block;
            width: 1.125rem;
            height: 1.125rem;
            border-radius: 0.25rem;
            border: thin solid var(--kb-color-checkbox-border);
            position: relative;

            .#{$kb-prefix}-checkbox__input:focus + .#{$kb-prefix}-checkbox__layout &,
            .#{$kb-prefix}-checkbox__input:active + .#{$kb-prefix}-checkbox__layout & {
                box-shadow: var(--kb-shadow--focus);
            }

            &::before {
                display: none;
                content: '';
                height: 0.25rem;
                width: 0.5rem;
                position: absolute;
                top: 42.5%;
                left: 50%;
                transform-origin: center;
                transform: translate3d(-50%, -50%, 0) rotate(-45deg);
                border-left: 2px solid var(--kb-color-brand);
                border-bottom: 2px solid var(--kb-color-brand);

                .#{$kb-prefix}-checkbox__input:checked + .#{$kb-prefix}-checkbox__layout & {
                    display: block;
                }
            }
        }

        &__layout {
            display: inline-flex;
        }

        &__label {
            display: block;
            line-height: 1;
            margin-left: 0.375rem;
            padding-top: 0.2rem;
        }
    }
}
